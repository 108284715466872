export const WalletIcon = ({ width = '24', height = '25' }: any) => (
  <svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.5 7.35156V17.8516C3.5 18.9562 4.39543 19.8516 5.5 19.8516H20.5C21.0523 19.8516 21.5 19.4039 21.5 18.8516V9.85156C21.5 9.29928 21.0523 8.85156 20.5 8.85156H5C4.17157 8.85156 3.5 8.17999 3.5 7.35156ZM3.5 7.35156C3.5 6.52313 4.17013 5.85156 4.99855 5.85156H18.5C19.0523 5.85156 19.5 6.29928 19.5 6.85156V8.35156M16.9 14.3516H17.1M17.5 14.3516C17.5 14.6277 17.2761 14.8516 17 14.8516C16.7239 14.8516 16.5 14.6277 16.5 14.3516C16.5 14.0755 16.7239 13.8516 17 13.8516C17.2761 13.8516 17.5 14.0755 17.5 14.3516Z'
      stroke='#595959'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
