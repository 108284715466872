import { SVG } from 'lib/interface';

export const UserOutlinedIcon = ({ className, color = '#757575' }: SVG) => (
  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.7 14.1328C14.675 14.1328 14.1821 14.7266 12.5 14.7266C10.8179 14.7266 10.3286 14.1328 9.3 14.1328C6.65 14.1328 4.5 16.3668 4.5 19.1203V20.0703C4.5 21.0537 5.26786 21.8516 6.21429 21.8516H18.7857C19.7321 21.8516 20.5 21.0537 20.5 20.0703V19.1203C20.5 16.3668 18.35 14.1328 15.7 14.1328ZM18.7857 20.0703H6.21429V19.1203C6.21429 17.3539 7.6 15.9141 9.3 15.9141C9.82143 15.9141 10.6679 16.5078 12.5 16.5078C14.3464 16.5078 15.175 15.9141 15.7 15.9141C17.4 15.9141 18.7857 17.3539 18.7857 19.1203V20.0703ZM12.5 13.5391C15.3393 13.5391 17.6429 11.1455 17.6429 8.19531C17.6429 5.24512 15.3393 2.85156 12.5 2.85156C9.66071 2.85156 7.35714 5.24512 7.35714 8.19531C7.35714 11.1455 9.66071 13.5391 12.5 13.5391ZM12.5 4.63281C14.3893 4.63281 15.9286 6.23223 15.9286 8.19531C15.9286 10.1584 14.3893 11.7578 12.5 11.7578C10.6107 11.7578 9.07143 10.1584 9.07143 8.19531C9.07143 6.23223 10.6107 4.63281 12.5 4.63281Z'
      fill='#595959'
    />
  </svg>
);
