import { SVG } from 'lib/interface';

export const AlarmOutlinedIcon = ({ color = '#595959' }: SVG) => (
  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_9466_86592)'>
      <path
        d='M12.4998 22.8516C13.5998 22.8516 14.4998 21.9516 14.4998 20.8516H10.4998C10.4998 21.9516 11.3998 22.8516 12.4998 22.8516ZM18.4998 16.8516V11.8516C18.4998 8.78156 16.8698 6.21156 13.9998 5.53156V4.85156C13.9998 4.02156 13.3298 3.35156 12.4998 3.35156C11.6698 3.35156 10.9998 4.02156 10.9998 4.85156V5.53156C8.13979 6.21156 6.49979 8.77156 6.49979 11.8516V16.8516L4.49979 18.8516V19.8516H20.4998V18.8516L18.4998 16.8516ZM16.4998 17.8516H8.49979V11.8516C8.49979 9.37156 10.0098 7.35156 12.4998 7.35156C14.9898 7.35156 16.4998 9.37156 16.4998 11.8516V17.8516ZM8.07979 4.93156L6.64979 3.50156C4.24978 5.33156 2.66979 8.15156 2.52979 11.3516H4.52979C4.60062 10.0834 4.95661 8.8475 5.57125 7.73595C6.18589 6.6244 7.04335 5.66582 8.07979 4.93156ZM20.4698 11.3516H22.4698C22.3198 8.15156 20.7398 5.33156 18.3498 3.50156L16.9298 4.93156C17.9618 5.66943 18.8154 6.62907 19.428 7.74003C20.0406 8.85098 20.3966 10.085 20.4698 11.3516Z'
        fill='#595959'
      />
    </g>
    <defs>
      <clipPath id='clip0_9466_86592'>
        <rect width='24' height='24' fill='white' transform='translate(0.5 0.851562)' />
      </clipPath>
    </defs>
  </svg>
);
